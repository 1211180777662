import styled from "@emotion/styled";
import Colors from "styles/colors";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";
export const StyledGridImageWithParagraph = styled.a`
  text-decoration: none;
  color: ${Colors.black};
  height: 100%;

  &.without-link {
    .grid-image-with-paragraph {
      cursor: initial;

      &:hover {
        transform: none;
      }
    }
  }

  .grid-image-with-paragraph {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 16px;

    background-color: ${Colors.white};
    transition: transform 300ms ease;
    ${getComponentShadow()}
    &:hover {
      ${getComponentShadowWithHoverEffect()}
    }

    &:hover {
      transform: scale(1.05);
    }

    .grid-paragraph-wrapper {
      height: 100%;

      margin-top: 16px;

      display: flex;
      flex-direction: column;

      .title-wrapper {
        width: 100%;
        margin-bottom: 16px;
        height: 64px;
        overflow: hidden;
      }

      .paragraph-body-wrapper {
        flex-grow: 1;
      }

      .button-wrapper {
        margin-top: 32px;
      }
    }
  }
`;
