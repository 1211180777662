function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import { MD, XS } from "constants/sizes";
const getGridImageWithParagraphProps = (imageSrc, fullParagraphTitle, fullParagraphBody)=>{
    const fullParagraph = _object_spread_props(_object_spread({}, GridImageWithParagraph.defaultProps.fullParagraph), {
        title: fullParagraphTitle,
        body: fullParagraphBody
    });
    return _object_spread_props(_object_spread({}, GridImageWithParagraph.defaultProps), {
        src: imageSrc,
        mobileImage: {
            src: imageSrc
        },
        fullParagraph,
        cardNumOfColumnsWidth: 1
    });
};
export default {
    shadowCards: true,
    removeShadowOnHover: false,
    gridAlignment: "center",
    gridItems: [
        getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png", "Cross-functional collab blog", "Look at your data differently with multiple View options from Calendar to Map."),
        getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png", "Relevant story/video", "Automate repetitive tasks and have more time for the work that motivates you."),
        getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png", "Productivity blog or marketing blog", "Get a high-level overview at a glance and make smarter decisions, faster."),
        getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png", "Productivity blog or marketing blog", "Get a high-level overview at a glance and make smarter decisions, faster.")
    ],
    paddingTop: "64px",
    paddingBottom: "64px",
    titleSize: XS,
    textSize: MD
};
