import styled from "@emotion/styled";
import Colors from "styles/colors";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
export const StyledGridParagraphWithImage = styled.div`
  text-decoration: none;
  color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
  height: 100%;
  width: 100%;
  display: flex;
  &.without-link {
    .grid-paragraph-with-image {
      cursor: initial;
    }
  }

  .grid-paragraph-with-image {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 18px;
    overflow: hidden;
    &.top {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.bottom {
      justify-content: flex-end;
    }
    .tag {
      border-style: solid;
      border-width: 2px;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 3px 5px;
    }

    &:not(.without-shadow-hover):hover {
      ${getComponentShadow()}
    }

    &.shadow {
      ${getComponentShadow()}
      &:not(.without-shadow-hover):hover {
        ${getComponentShadowWithHoverEffect()}
      }
    }

    .grid-image-wrapper {
      .grid-image {
        .picture-component {
          display: flex;
          img {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }
      }
    }

    .grid-paragraph-wrapper {
      height: 100%;
      padding: 24px 24px 40px 24px;
      display: flex;
      flex-direction: column;

      .full-paragraph-topic-image-wrapper.left .picture-component > img {
        object-fit: contain;
      }
      
      .footer {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 10px;
        .footer-image {
          width: ${({ footerImageWidth })=>footerImageWidth !== null && footerImageWidth !== void 0 ? footerImageWidth : "auto"};
          height: auto;
        }
      }
    }
  }

  &.disabled-hover-effect {
    cursor: initial;

    .grid-paragraph-with-image {
      cursor: initial;

      &:hover {
        box-shadow: none;
      }
    }
  }
`;
